.solif .header {
  background-image: url("../img/apex-header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 9% 100px;
}
.solif .header .box {
  background-color: var(--blue);
  width: 100%;
  padding: 30px 10px;
}
.solif .header .btn-cta {
  padding: 16px 60px;
  background-color: #E26C2B;
  border: 4px solid #E26C2B;
}
.solif .header .btn-cta:hover {
  color: #E26C2B;
  background-color: var(--white);
}
.solif .header .lbox {
  background-color: var(--white);
  margin-top: 3%;
  margin-bottom: 3%;
}
.solif .header .link {
  color: var(--dark-grey);
  margin: 10px;
}
.solif .header h1 {
  margin-top: 5%;
}
@media (min-width: 1920px) {
  .solif .header .lbox {
    background-color: var(--white);
    margin-top: 2%;
    margin-bottom: 2%;
  }
}
@media (max-width: 500px) and (min-width: 0px) {
  .solif .header {
    padding: 10px 0px 80px;
  }
  .solif .header .row {
    padding: 0px 9%;
  }
  .solif .header nav {
    background-color: transparent;
    margin-top: 3%;
    position: relative;
  }
  .solif .header nav .navbar-toggler {
    border: none;
  }
  .solif .header nav .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .solif .header nav .navbar-nav {
    margin-top: 10%;
    position: absolute;
    text-align: center;
    background-color: var(--white);
    padding: 2%;
    width: 100%;
    border-radius: 20px;
  }
  .solif .header nav .navbar-toggler-icon {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
    background-image: url("../img/menu-res.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .solif .header nav .link {
    color: var(--blue) !important;
    font-size: 16px;
    margin: 5px;
  }
  .solif .header .box {
    text-align: center;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30%;
    padding: 20px 7px;
  }
  .solif .header .box img {
    width: 70%;
  }
  .solif .header h1 {
    margin-top: 12% !important;
  }
}
.solif .sec1 .glide .glide__arrows .glide__arrow {
  background-color: none;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  top: 27% !important;
}
.solif .sec1 .glide .glide__arrows .glide__arrow--left {
  left: -60px;
}
.solif .sec1 .glide .glide__arrows .glide__arrow--right {
  right: -60px;
}
@media (max-width: 500px) and (min-width: 0px) {
  .solif .sec1 .glide {
    width: 100%;
  }
  .solif .sec1 .glide .glide__slide img {
    width: 100%;
  }
  .solif .sec1 .glide .glide__arrows {
    display: none;
  }
}
.solif .sec2 .glide2 .box {
  padding: 30px 2%;
}
.solif .sec2 .glide2 .box p {
  margin: 25px 0px;
}
.solif .sec2 .glide2 .glide__arrows .glide__arrow {
  background-color: none;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  top: 50% !important;
}
.solif .sec2 .glide2 .glide__arrows .glide__arrow--left {
  left: -60px;
}
.solif .sec2 .glide2 .glide__arrows .glide__arrow--right {
  right: -60px;
}
@media (max-width: 500px) and (min-width: 0px) {
  .solif .sec2 .glide2 {
    width: 100%;
  }
  .solif .sec2 .glide2 .glide__arrows {
    display: none;
  }
}
.solif .sec3 .glide3 .glide__arrows .glide__arrow {
  background-color: none;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  top: 50% !important;
}
.solif .sec3 .glide3 .glide__arrows .glide__arrow--left {
  left: -60px;
}
.solif .sec3 .glide3 .glide__arrows .glide__arrow--right {
  right: -60px;
}
.solif .sec3 .fondo {
  background-image: url("../img/apex-choose.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  margin-top: -30%;
}
@media (min-width: 1920px) {
  .solif .sec3 .fondo {
    margin-top: -20%;
    min-height: 300px;
  }
}
@media (max-width: 500px) and (min-width: 0px) {
  .solif .sec3 .glide3 {
    width: 100%;
  }
  .solif .sec3 .glide3 .glide__arrows {
    display: none;
  }
  .solif .sec3 .fondo {
    margin-top: -95%;
    max-height: 150px;
  }
}
.solif .sec4 p {
  margin-bottom: 5%;
}
.solif .fondo {
  background-image: url("../img/apex-location.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 9% 80px;
}
.solif .fondo .mapa {
  margin-top: -8%;
  height: 500px;
}
@media (max-width: 500px) and (min-width: 0px) {
  .solif .fondo {
    padding-bottom: 50px;
  }
  .solif .fondo .mapa {
    height: 200px;
  }
}
.solif .footer {
  background-image: url("../img/apex-footer.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 80px 9% 20px;
}
.solif .footer p {
  padding-right: 20px;
}
.solif .footer .btn .icono {
  max-width: 36px;
  max-height: 36px;
  margin-right: 20px;
}
.solif .footer .copy {
  padding: 0px;
}
.solif .footer .box {
  position: absolute;
  background-color: var(--grey);
  padding: 20px 88px;
  bottom: 0;
}
.solif .footer form .form-control {
  background-color: var(--white);
  color: var(--dark-grey);
  font-size: 20px;
  border: none;
  border-radius: 0px;
  padding: 15px 20px;
}
.solif .footer form .form-control::-webkit-input-placeholder {
  color: var(--dark-grey);
  font-size: 20px;
}
.solif .footer form .form-control::-moz-placeholder {
  color: var(--dark-grey);
  font-size: 20px;
}
.solif .footer form .form-control:-ms-input-placeholder {
  color: var(--dark-grey);
  font-size: 20px;
}
.solif .footer form .form-control::-ms-input-placeholder {
  color: var(--dark-grey);
  font-size: 20px;
}
.solif .footer form .form-control::placeholder {
  color: var(--dark-grey);
  font-size: 20px;
}
.solif .footer form .btn-cta {
  color: var(--blue) !important;
  background-color: var(--grey);
  border: 1px solid var(--grey);
  padding: 12px 50px;
}
.solif .footer form .btn-cta:hover {
  color: var(--blue) !important;
  background-color: var(--white);
  border: 1px solid var(--grey);
}
@media (max-width: 500px) and (min-width: 0px) {
  .solif .footer {
    text-align: center;
  }
  .solif .footer .btn {
    margin-bottom: 20px;
  }
  .solif .footer p {
    padding-right: 0px;
  }
  .solif .footer .copy {
    margin-bottom: 60%;
  }
  .solif .footer .box {
    width: 82%;
  }
}