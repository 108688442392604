:root {
  --dark-grey: #373736;
  --grey: #dadada;
  --white: #ffffff;
  --blue: #131a77;
}

@font-face {
  font-family: "Heebo";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "MuktaMahee";
  src: url("../fonts/MuktaMahee-ExtraBold.ttf") format("opentype");
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "MuktaMahee";
  src: url("../fonts/MuktaMahee-Light.ttf") format("opentype");
  font-style: normal;
  font-weight: 300;
}
html, body {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  color: var(--dark-grey);
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

h1 {
  font-size: 60px;
  font-family: "MuktaMahee", sans-serif;
}

h2 {
  font-size: 45px;
  font-family: "MuktaMahee", sans-serif;
}

h3 {
  font-size: 35px;
  font-family: "MuktaMahee", sans-serif;
}

.d-pad {
  padding: 80px 9%;
}

.bg-blue {
  background-color: var(--blue);
}

.muk {
  font-family: "MuktaMahee", sans-serif;
}

.fs-35 {
  font-size: 35px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.text-blue {
  color: var(--blue);
}

.fw-l {
  font-weight: 300;
  font-family: "MuktaMahee", sans-serif;
}

a {
  color: unset;
  text-decoration: none;
}
a:hover {
  color: unset;
}

.btn-cta {
  padding: 13px 20px;
  display: inline-block;
  background-color: var(--blue);
  color: var(--white);
  text-align: center;
  border-radius: none;
  text-decoration: none;
  border: 1px solid var(--blue);
  font-size: 18px;
}
.btn-cta:hover {
  color: var(--blue);
  background-color: var(--white);
}

.body-gracias {
  background-image: url("../img/apex-header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-item {
  display: none;
}

@media (max-width: 500px) and (min-width: 0px) {
  .desktop-item {
    display: none !important;
  }
  .mobile-item {
    display: block !important;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 35px;
  }
  h3 {
    font-size: 25px;
  }
}